<template>
  <div class="sesiones mx-2">
    <v-row>
      <v-col cols="12" md="12" sm="12">
        <v-card>
          <v-card-title>Lista de sesiones activas</v-card-title>
          <v-card-subtitle
            >En este apartado encontrarás todas las sesiones activas para este módulo recepción, 
            <strong>
              recuerda que el sistema solo permite tener dos sesiones activas
            </strong>
          </v-card-subtitle>
          <v-card-text>
            <v-row dense>
              <v-col
                cols="12"
                md="6"
                sm="6"
                v-for="(item, index) in sessions"
                :key="index"
              >
                <v-card outlined>
                  <v-list-item three-line>
                    <v-list-item-content>
                      <div class="text-overline mb-4">
                        {{ item.session }}
                      </div>
                      <v-list-item-title class="text-h5 mb-1">
                        {{ item.data.device }}
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        IP: {{ item.data.ip }}
                      </v-list-item-subtitle>
                    </v-list-item-content>

                    <v-list-item-avatar tile size="80">
                      <img :src="item.img" />
                    </v-list-item-avatar>
                  </v-list-item>
                  <v-card-actions>
                    <v-btn
                      text
                      color="error"
                      @click="closeSession(item.session)"
                      v-if="item.session != session_start"
                    >
                      Cerrar sesión
                    </v-btn>
                    <v-btn
                      text
                      color="success"
                      v-if="item.session == session_start"
                    >
                      Sesión actual
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
const base = "./img/browsers/";
export default {
  data: () => ({
    session_start: "",
    browsers: [
      {
        browser: "Unknown Browser",
        img: base + "chrome.png",
      },
      {
        browser: "Internet Explorer",
        img: base + "edge.png",
      },
      {
        browser: "Firefox",
        img: base + "firefox.png",
      },
      {
        browser: "Safari",
        img: base + "safari.png",
      },
      {
        browser: "Chrome",
        img: base + "chrome.png",
      },
      {
        browser: "Edge",
        img: base + "edge.png",
      },
      {
        browser: "Opera",
        img: base + "opera.png",
      },
      {
        browser: "Netscape",
        img: base + "netscape.png",
      },
      {
        browser: "Maxthon",
        img: base + "maxthon.png",
      },
      {
        browser: "Konqueror",
        img: base + "konqueror.png",
      },
      {
        browser: "UC Browser",
        img: base + "uc.png",
      },
      {
        browser: "Safari Browser",
        img: base + "safari.png",
      },
    ],
    sessions: [],
  }),
  methods: {
    loadSessions() {
      const body = {
        route: "/sessions",
      };
      this.$store.dispatch("axios_get", body).then((response) => {
        if (response.data.success) {
          this.session_start = localStorage.getItem("session_start");
          this.sessions = response.data.data.map((session) => {
            let img = "";
            this.browsers.forEach((bw) => {
              if (bw.browser == session.data.browser) {
                img = bw.img;
              }
            });
            return Object.assign({ img }, session);
          });
        }
      });
    },
    closeSession(input) {
      const body = {
        route: "/close_sesion",
        data: {
          session: input,
        },
      };
      this.$store.dispatch("axios_post", body).then((response) => {
        if (response.data.success) {
          this.loadSessions();
        }
      });
    },
  },
  created() {
    this.loadSessions();
  },
  mounted() {
    this.$store.dispatch("loadNav", true);
    const body = {
      permisos: JSON.stringify(["informes", "recepcion", "bloqueos", "caja", 'callc']),
    };
    this.$store
      .dispatch("tokenValid", body)
      .then((response) => {
        if (!response.data.access_view) {
          this.$router.push("/home");
        }
      })
      .catch((error) => {
        console.log(error);
        if (error.response.status == 401) {
          localStorage.removeItem("access_token");
          localStorage.removeItem("session_start");
          window.location.reload();
        }
      });
  },
};
</script>
